import React from "react";
import { Link } from 'react-router-dom'

export default function BlogNews() {
    return (
        <section className="blognews mt-10">
            <div className="py-10 lg:py-14">
                <h1 className="lg-max-w-4xl md-max-w-2xl text-3xl lg:text-5xl text-center text-text-color tracking-normal font-medium">Read about some of our
                    <br></br> <span className="font-serif italic text-primary font-bold">work and stories</span>
                </h1>

                <div className="news flex pt-8 px-6 lg:px-0 lg:gap-x-4 flex-col lg:flex-row align-center justify-center">
                    
                    <div className="blog-lg w-full h-auto lg:w-[700px] lg:h-[455px] bg-cover bg-no-repeat"
                        style={{
                            backgroundImage: `linear-gradient(to bottom, rgba(97, 97, 97, 0.3) 30%, rgba(0, 0, 0, 0.9) 90%), url('https://blog.shecluded.com/wp-content/uploads/2025/03/Shecluded-Gold-banner-1.png')`
                        }}> 

                        <div className="blog-lg-content pb-6 mt-48 lg:mt-64 px-6">
                            <h1 className="text-white font-medium text-3xl">Shecluded Launches Gold to Bridge the Growth Gap for Women Entrepreneurs
                            </h1>
                            <div className="blog-date flex flex-row text-light-grey-2 text-base pt-4 pb-4">
                                <p>Read</p>
                                <p className="px-2">|</p>
                                <p className="">March 12, 2025</p>
                            </div>
                            <Link to="https://blog.shecluded.com/shecluded-launches-gold-to-bridge-the-growth-gap-for-women-entrepreneurs/" className="text-lg font-semibold leading-10 underline-offset-4 tracking-normal text-white underline">
                                Learn more
                            </Link>
                        </div>
                    </div>
                    <div className="blog-sm flex flex-col mt-4 lg:mt-0">
                        <Link to='https://blog.shecluded.com/alx-and-shecludeds-bold-initiative-for-financial-empowerment/' className="">
                            <div className="blog-sm-content flex flex-col lg:flex-row gap-y-4 lg:gap-y-0">
                                <img src="https://blog.shecluded.com/wp-content/uploads/2025/02/IMG_2192-scaled.jpg" className="w-full h-auto lg:w-[200px] lg:h-[130px] object-cover" alt="women at a shecluded event"></img>
                                <div className="blog-details text-dark-grey-2 pt-0 lg:pl-2">
                                    <p className="text-sm lg:text-base font-medium">SHECLUDEDNEWS</p>
                                    <h1 className="text-text-color font-semibold text-base lg:text-base uppercase pt-2">ALX and Shecluded’s Bold <br></br> Initiative for Financial<br></br> Empowerment          
                                   </h1>
                                    <div className="blog-date flex flex-row text-dark-grey-2 text-sm lg:text-base pt-2 ">
                                        <p>Read</p>
                                        <p className="px-2">|</p>
                                        <p className="">February 25, 2025</p>
                                    </div>
                                </div>

                            </div>
                        </Link>

                        <Link to='https://blog.shecluded.com/what-weve-learned-from-supporting-50000-women-entrepreneurs-acrossnigeria/' className="py-6">
                            <div className="blog-sm-content flex flex-col lg:flex-row gap-y-4  lg:gap-y-0">
                                <img src="https://blog.shecluded.com/wp-content/uploads/2025/02/Shecluded-Milestone-2025-scaled.jpg" className="w-full h-auto lg:w-[200px] lg:h-[130px] object-cover" alt="women at a shecluded event"></img>
                                <div className="blog-details text-dark-grey-2 pt-0 lg:pl-2">
                                    <p className="text-sm lg:text-base font-medium">SHECLUDEDNEWS</p>
                                    <h1 className="text-text-color font-semibold text-base lg:text-base uppercase pt-2">What We’ve Learned from<br></br> Supporting 50,000 Women<br></br> Entrepreneurs across Nigeria
                                   </h1>
                                    <div className="blog-date flex flex-row text-dark-grey-2 text-sm lg:text-base pt-2">
                                        <p>Read</p>
                                        <p className="px-2">|</p>
                                        <p className="">February 10, 2025</p>
                                    </div>
                                </div>

                            </div>
                        </Link>

                        <Link to='https://blog.shecluded.com/the-making-of-afrikayla-how-ibijoke-oyindamola-shallangwa-is-styling-the-stars/'>
                            <div className="blog-sm-content flex flex-col lg:flex-row gap-y-4  lg:gap-y-0">
                                <img src="https://blog.shecluded.com/wp-content/uploads/2025/02/Ibijoke-Oyindamola-Shallangwa-1.jpg" className="w-full h-auto lg:w-[200px]  lg:h-[130px]  object-cover" alt="women at a shecluded event"></img>
                                <div className="blog-details text-dark-grey-2 lg:pt-2 lg:pl-2">
                                    <p className="text-sm lg:text-basefont-medium">SHECLUDEDNEWS</p>
                                    <h1 className="text-text-color font-semibold text-md lg:text-base uppercase pt-1">The Making of Afrikayla How <br></br>Ibijoke Oyindamola Shallangwa<br></br> is Styling the Stars                                    </h1>
                                    <div className="blog-date flex flex-row text-dark-grey-2 text-sm lg:text-base pt-1">
                                        <p>Read</p>
                                        <p className="px-2">|</p>
                                        <p className="">February 7, 2025</p>
                                    </div>
                                </div>

                            </div>
                        </Link>

                    </div>
                </div>
            </div>
        </ section>
    )
}

